<template>
  <div>
    <div v-show="exactRoute">
      <v-card>
        <v-card-title>
          Archiv poptávek
          <v-spacer></v-spacer>
          <vc-date-picker
            v-model="dateFilter"
            is-range
            :columns="2"
            color="blue"
            :popover="{ visibility: 'focus' }">
            <template v-slot="{ dateFilter: inputValue, inputEvents }">
              <div class="d-flex justify-center items-center">
                <v-text-field
                  outlined
                  :value="getLocalizedDate(dateFilter.start)"
                  v-on="inputEvents.start"
                  hide-details
                  readonly
                  placeholder="Od"
                  class="rounded-0 border-right-none"
                  dense
                  style="max-width: 120px"
                />
                <v-text-field
                  class="rounded-0 border-left-none"
                  outlined
                  :value="getLocalizedDate(dateFilter.end)"
                  v-on="inputEvents.end"
                  hide-details
                  dense
                  readonly
                  placeholder="Do"
                  style="max-width: 120px"
                />
              </div>
            </template>
          </vc-date-picker>
          <v-select
            deletable-chips
            small-chips
            dense
            outlined
            hide-selected
            hide-details
            v-model="firmFilter"
            class="rounded-0"
            label="Filtr stavu"
            :items="[{
              text: 'Závazná',
              value: 'true'
            }, {
              text: 'Nezávazná',
              value: 'false'
            }]"
          >
          </v-select>
          <v-form v-model="searchValid" class="d-flex">
            <v-text-field
            v-model.trim="search"
            append-icon="mdi-magnify"
            label="Vyhledat"
            :rules="[validation.required]"
            single-line
            hide-details
            outlined
            ref="searchInput"
            dense
            class="rounded-0"
            ></v-text-field>
            <v-select
              outlined
              class="rounded-0"
              dense
              single-line
              hide-details
              ref="searchTypeInput"
              label="Vyhledat podle"
              :rules="[validation.required]"
              v-model="searchType"
              :items="searchTypes"
            >
            </v-select>
            <v-btn
              color="primary"
              :disabled="!searchValid || runningRequest"
              @click="filterBySearch"
              class="rounded-0"
            >Vyhledat</v-btn>
            <v-btn
              color="error"
              @click="resetSearch()"
              class="rounded-0"
              :disabled="!searchActive || runningRequest"
            >
              Zrušit vyhledávání
            </v-btn>
          </v-form>
        </v-card-title>
        <v-data-table
          :headers="headers"
          :items="demands"
          class="elevation-1"
          :loading="runningRequest"
          :server-items-length="demandsTotal"
          :options.sync="paginationOptions"
          :footer-props="{'items-per-page-options': [10, 20, 50] }"
        >
          <template v-slot:item.userName="{ item }">
            <a :href="`/admin/users/${item.user.id}`" class="table-link">
              <!-- {{ item.user.firstName }} {{ item.user.lastName }} -->
              {{ item.user.email }}
            </a>
          </template>
          <template v-slot:item.hostName="{ item }">
            <a :href="`/admin/hosts/${item.property.owner.id}`" class="table-link">
              {{ item.property.owner.firstName }} {{ item.property.owner.lastName }}
            </a>
          </template>
          <template v-slot:item.firm="{ item }">
            <v-chip
              :color="getColor(item)"
              dark
            >
              {{ getFirmText(item) }}
            </v-chip>
          </template>
          <template v-slot:item.propertyName="{ item }">
            <a :href="`/admin/properties/${item.property.id}`" class="table-link">
              {{ item.property.name }}
            </a>
          </template>
          <template v-slot:item.dateFrom="{ item }">
            {{ item.dateFrom | localizedDate }}
          </template>
          <template v-slot:item.dateTo="{ item }">
            {{ item.dateTo | localizedDate }}
          </template>
          <template v-slot:item.createdAt="{ item }">
            {{ item.createdAt | localizedDate }}
          </template>
          <template v-slot:item.actions="{ item }">
            <v-icon
              small
              class="mr-2"
              @click="redirectToDetail(item)"
            >
              mdi-information-variant
            </v-icon>
          </template>
          <template v-slot:item.deleted="{ item }">
            <span v-if="item.deletionReason === 4">
              Zrušeno uživatelem
            </span>
            <span v-if="item.deletionReason === 3">
              Zrušeno hostitelem
            </span>
            <span v-if="item.deletionReason === 2">
              Smazáno adminem {{ item.deletedBy.email }}
            </span>
            <span v-if="item.deletionReason === 1">
              Archivováno automaticky
            </span>
            <span v-if="item.deletionReason === 5">
              Archivováno s kartou objektu
            </span>
          </template>
        </v-data-table>
      </v-card>
    </div>
    <router-view ref="childView"></router-view>
  </div>
</template>

<script>
import demandsOverviewHelpers from '../../mixins/local/demandsOverviewHelpers'
import { mapMutations } from 'vuex'

export default {
  name: 'Demands',
  mixins: [demandsOverviewHelpers],
  data () {
    return {
      search: '',
      searchType: null,
      searchValid: true,
      searchTypes: [],
      searchActive: false,
      dateFilter: {
        start: null,
        end: null
      },
      firmFilter: null,
      headers: [
        { text: 'Poptávající', align: 'start', value: 'userName' },
        { text: 'Číslo poptávky', value: 'id' },
        { text: 'Hostitel', value: 'hostName' },
        { text: 'Typ', value: 'firm' },
        { text: 'Objekt', value: 'propertyName' },
        { text: 'Vytvořeno', value: 'createdAt' },
        { text: 'Od', value: 'dateFrom' },
        { text: 'Do', value: 'dateTo' },
        { text: 'Smazáno', value: 'deleted', sortable: false },
        { text: 'Akce', value: 'actions', sortable: false }
      ],
      runningRequest: false,
      demands: [],
      demandsTotal: 0,
      exactRoute: true
    }
  },
  methods: {
    /**
     * Loads demands from backend
     * @return void
     */
    getDemands () {
      this.runningRequest = true
      const { sortBy, sortDesc, page, itemsPerPage } = this.paginationOptions

      this.$http.get('/admin/demands/archive', {
        params: {
          sortBy: sortBy.length ? sortBy[0] : null,
          sortDesc: sortDesc.length ? sortDesc[0] : null,
          search: this.search || null,
          // eslint-disable-next-line no-extra-boolean-cast
          searchType: !!this.searchType ? this.searchType : null,
          from: this.dateFilter.start ? this.dateFilter.start.toISOString().slice(0, 10) : null,
          to: this.dateFilter.end ? this.dateFilter.end.toISOString().slice(0, 10) : null,
          firm: this.firmFilter,
          page,
          itemsPerPage
        }
      })
        .then(res => {
          this.demands = res.data.demands
          this.demandsTotal = +res.data.total
        })
        .finally(() => {
          this.runningRequest = false
        })
    },
    /**
     * Debounced getDemands
     * @return void
     */
    loadDemands () {},
    /**
     * Redirects to demand detail
     */
    redirectToDetail (demand) {
      this.$router.push({
        name: 'admin.demands-archive.demand',
        params: {
          id: demand.id
        }
      })
    },
    filterBySearch () {
      this.searchActive = true
      this.getDemands()
    },
    resetSearch () {
      this.$refs.searchInput.reset()
      this.$refs.searchTypeInput.reset()
      this.searchActive = false
      this.getDemands()
    },
    ...mapMutations({
      setAlerts: 'alerts/setAlerts'
    })
  },
  watch: {
    firmFilter: {
      handler () {
        this.loadDemands()
      }
    },
    dateFilter: {
      deep: true,
      handler () {
        this.loadDemands()
      }
    }
  },
  mounted () {
    this.getDemands()
    this.loadDemands = this.debounce(this.getDemands, 2000)
    this.exactRoute = typeof this.$refs.childView === 'undefined'

    this.searchTypes = this.headers.filter(header => {
      const searchables = ['userName', 'hostName', 'propertyName', 'dateFrom', 'dateTo']

      return searchables.includes(header.value)
    })
  },
  updated () {
    this.exactRoute = typeof this.$refs.childView === 'undefined'
  }
}
</script>

<style scoped>

</style>
